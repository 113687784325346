import React from "react";
import { Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import twitter from "./twitter32.png";
import opensea from "./OpenSea32.png";




export const Header = () => {

return (
    <div className="App">
    <Navbar expand="lg" variant="light" bg="light">
      <Container>
        <Navbar.Brand href="https://twitter.com/MTLXxyz" target="_blank" rel="noopener noreferrer"><img src={twitter} alt=""/></Navbar.Brand>
        <Navbar.Brand href="https://opensea.io/collection/metallicangels" target="_blank" rel="noopener noreferrer"><img src={opensea} alt=""/></Navbar.Brand>
      </Container>
    </Navbar>
    </div>
)
}