import React from "react";
import "./Footer.css";


export const Footer = () => {

return (

    <div className="footer">
        METALLIC ANGELS
    </div>

)


}