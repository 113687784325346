import React from "react";
import './App.css';
import {SliderImg} from "./components/Slider";
import {Header} from "./components/Navbar";
import {Footer} from "./components/Footer";
import banner from "./utils/images/name.GIF";

function App() {
 

    return (
      <div>
        <Header/>
        <img src={banner} alt="" className="banner"/>
        <SliderImg/>
        <Footer/>
      </div>
     
    );
}

export default App;