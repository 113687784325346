import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './slider.css';
import angel from './1652.jpg';
import angel1 from './1640.JPG';
import angel2 from './1681.JPG';
import angel3 from './1646.png';
import angel4 from './1647.png';
import angel5 from './1672.png';
import angel6 from './1668.png';
import angel7 from './1697.png';

export const SliderImg = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        rtl: true
      };

      return (
<Slider {...settings}>
          <div>
            <img src={angel} className="angels" alt=''/>
          </div>
          <div>
          <img src={angel1} className="angels" alt=''/>
          </div>
          <div>
          <img src={angel2} className="angels" alt=''/>
          </div>
          <div>
          <img src={angel3} className="angels" alt=''/>
          </div>
          <div>
          <img src={angel4} className="angels" alt=''/>
          </div>
          <div>
          <img src={angel7} className="angels" alt=''/>
          </div>          
          <div>
          <img src={angel5} className="angels" alt=''/>
          </div>
          <div>
          <img src={angel6} className="angels" alt=''/>
          </div>          
        </Slider>
)
}